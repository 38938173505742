import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Flex, Box } from 'rebass'
import { Link } from 'gatsby'

import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import CategorySelector from '../components/elements/CategorySelector'
import ArticleGrid from '../components/blocks/ArticleGrid'
import Heading, { StyledHeading } from '../components/elements/Heading'
import BodyText, { StyledBodyText } from '../components/elements/BodyText'
import Button from '../components/elements/Button'
import useImageBrightness from '../hooks/useImageBrightness'
import theme from '../themes'

const StyledBlogPage = styled.div`
  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }

    &:active {
      color: ${props => props.theme.colors.linkActive};
    }
  }

  > ${Flex} {
    > ${Box} {
      max-width: 1168px;
    }
  }

  > ${Flex}.masthead {
    position: relative;
    max-height: 600px;
    height: 600px;
    background-size: cover;
    background-position: center center;
    color: ${props => props.theme.colors.white};

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.35);
    }

    &.bg-overlay {
      &:before {
        background-color: rgba(0, 0, 0, 0.5);
      }

      h2,
      p {
        text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      }
    }

    ${StyledHeading} {
      color: #fff;
      line-height: 3rem;
      @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
        line-height: 5rem;
      }
    }

    ${StyledBodyText} {
      p {
        margin-bottom: 2rem;
      }
    }

    div {
      position: relative;
      z-index: 2;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints[2]}) {
      height: 500px;
      max-height: 500px;
    }
  }

  .sectionHeading {
    ${StyledHeading} {
      color: white;
      position: relative;

      &:after {
        color: white;
      }
    }
  }
`

const BlogPage = ({ pageContext }) => {
  const { articles, categories } = pageContext
  const featuredArticle = articles.find(article => article.featured)
  const mastheadArticle = featuredArticle || articles[0]
  const remainingArticles = articles.filter(({ id }) => id !== mastheadArticle.id)

  const heroBrightness = useImageBrightness(mastheadArticle.mastheadImage.file.url)
  const hasLightHero = heroBrightness > 175

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title="POWERSHiFTER Blog | Insights from Experts"
        description="Get the latest digital product and service design insights from our experts to help you create simple and easy-to-use mobile apps, web apps, and websites."
        canonical="https://powershifter.com/blog/"
      />
      <StyledBlogPage>
        <Header variant="transparent" hasLightBackground={hasLightHero} />
        <Flex
          className={mastheadArticle.darkBackgroundOverlay ? 'masthead bg-overlay' : 'masthead'}
          pt={['120px']}
          pb={['60px']}
          style={{
            backgroundImage: mastheadArticle.mastheadImage
              ? `url('https:${mastheadArticle.mastheadImage.file.url}')`
              : '',
            boxSizing: 'content-box'
          }}
          alignItems="center"
        >
          <Box mx="auto">
            <Box mx="auto" px={[0, null, null, null, 0]} width={[10 / 12, null, null, 10 / 12]}>
              <Box>
                <Heading is="h1" postsymbol="">
                  {mastheadArticle.title}
                </Heading>
                <BodyText>{mastheadArticle.description}</BodyText>
              </Box>

              <Link to={`/blog/${mastheadArticle.slug}/`} target="_blank">
                <Button variant={hasLightHero ? 'primary' : 'inverted'} mt={[3]}>
                  Read article
                </Button>
              </Link>
            </Box>
          </Box>
        </Flex>
        <CategorySelector categories={categories} />
        <ArticleGrid articles={remainingArticles} />
        <Footer />
      </StyledBlogPage>
    </ThemeProvider>
  )
}

export default BlogPage
